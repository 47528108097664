import React, { PropsWithChildren } from "react";
import { HeightRestriction, WidthRestriction } from "../../interfaces/Restrictions";
import {
  TitleContainer,
  ContentContainer,
  ImageLarge,
  TitleWithImageContainer,
} from "../components/cardStyles";
import { ADMIN_IMAGES_URL } from "@appsinti/common-utils/constants/urls";
import { SummaryApp } from "@appsinti/common-utils/interfaces/IApp";
import IGroup from "@appsinti/common-utils/interfaces/IGroup";
import ChevronRight from "../components/CevronRight";
import { usePreviewData } from "../data";
import WeatherInfo from "../components/WeatherInfo";

const CardContainer: React.FC<
  PropsWithChildren<{
    app: SummaryApp;
    groups: { [key: number]: IGroup };
    groupId: number;
    widthRestriction: WidthRestriction;
    heightRestriction: HeightRestriction;
  }>
> = ({ app, groups, groupId, widthRestriction, heightRestriction, children }) => {
  const { preview } = usePreviewData();

  return (
    <>
      <TitleContainer className={widthRestriction}>
        <TitleWithImageContainer className={widthRestriction}>
          <div className="image">
            <ImageLarge
              src={`${ADMIN_IMAGES_URL}/${groups[groupId].imageLight}`}
            />
          </div>
          <p>{groups[groupId].name}</p>
        </TitleWithImageContainer>
        {preview?.weatherData && (
            <WeatherInfo widthRestriction={widthRestriction} heightRestriction={heightRestriction} weatherData={preview.weatherData} />
          )}
        <ChevronRight className={`${widthRestriction} ${heightRestriction}`} bigger={Object.values(groups).length <= 3} />
      </TitleContainer>
      <ContentContainer>
        {app.module === "stats-summary" && children}
      </ContentContainer>
    </>
  );
};

export default CardContainer;
