import React, { useRef, useEffect } from "react";
import styled from "styled-components";

import { DependencySelector } from "./DependencySelector";
import { ShowMenu } from "../../../../components/ShowMenu";
import Container from "@appsinti/ui-core/header/Container";
import { getToday } from "@appsinti/ui-core/dateAndTime/utils";

import useI18n from "@appsinti/i18n/useI18n";
import { Link } from "react-router-dom";
import HomeIcon from "../../../../../../resources/img/home-icon.svg";
import { useApps } from "pages/home/AppProvider";

const DISPLAY_DATE_FORMAT = "iiii MMMM dd, yy";

const Icon = styled(Link)`
  flex: 0 0 23%;
  max-width: 23%;
  width: 20px;
  display: block;

  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
`;

const Date = styled.div`
  flex: 0 0 23%;
  max-width: 23%;
  font-size: 1.2rem;
`;

const Header: React.FC<{
  setHeaderHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
  headerHeight: number | undefined;
}> = ({ setHeaderHeight, headerHeight }) => {
  const { selectedLang } = useI18n();
  const containerRef = useRef<HTMLHeadElement>(null);
  const { summaryApps } = useApps();

  useEffect(() => {
    setHeaderHeight(containerRef.current?.clientHeight);
  }, [containerRef.current?.clientHeight]);

  return (
    <Container ref={containerRef}>
      {summaryApps?.length === 0 ? (
        <Date>{getToday(selectedLang, DISPLAY_DATE_FORMAT)}</Date>
      ) : (
        <Icon to={"/"}>
          <img src={HomeIcon} alt="Appsinti" />
        </Icon>
      )}
      <DependencySelector headerHeight={headerHeight} />
      <ShowMenu headerHeight={headerHeight} />
    </Container>
  );
};

export default Header;
