import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import MainCard from "@appsinti/ui-core/MainCard";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { Row, FullWidth, CardTitle } from "@appsinti/ui-core/styles";

import useI18n from "@appsinti/i18n/useI18n";
import IVideo from "./interfaces/IVideo";
import { getData } from "./services/cameras";
import { useUpdate } from "@appsinti/trigger-update";
import { Ring } from "@uiball/loaders";
import { getPreviewMirror, updatePreviewMirror } from "@appsinti/preview-mirror";
import CamerasPreview, { isCamerasPreview } from "./interfaces/CamerasPreview";

const CamerasGrid = styled.div`
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 5px;
`;

const PreviewImage = styled.div<{ previewImage: string }>`
  background-image: url(${({ previewImage }) => previewImage});
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const Preview: React.FC<{ appId: number }> = ({ appId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { translate } = useI18n();
  const [previewCameras, setPreviewCameras] = useState<IVideo[] | CamerasPreview | null>(null);
  const { translateObject } = useI18n();
  const { update, addLoadingState, resolveLoadingState } = useUpdate();

  async function fetchPreviewCameras(appId: number): Promise<CamerasPreview | null> {
    try {
      setLoading(true);
      addLoadingState('bigbrother-frontend');
      const { previews } = await getData(appId);

      const data: CamerasPreview = {
        feeds: previews,
        lastUpdate: new Date().getTime(),
      };
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      resolveLoadingState('bigbrother-frontend');
    }
    return null;
  }

  const storeLocalPreview = async () => {
    if(!appId) return;
    const previewData = await fetchPreviewCameras(appId);
    setPreviewCameras(previewData);
    if(previewData) {
      updatePreviewMirror(JSON.stringify(previewData), appId);
    }
}

  const getLocalPreview = (appId: number) => {    
    const preview = getPreviewMirror(appId);
    if (!preview) { 
      storeLocalPreview();
    }
    else {
      setPreviewCameras(JSON.parse(preview) as IVideo[] | CamerasPreview);
    }
  }

  useEffect(() => {
    if(!appId) return;
    getLocalPreview(appId);
  }, [appId]);

  useEffect(() => {
    storeLocalPreview();
  }, [update]);

  const lastUpdatedTime = useMemo(() => {
    if (previewCameras && isCamerasPreview(previewCameras) && previewCameras.lastUpdate) {
      return new Date(previewCameras.lastUpdate);
    }
  }, [previewCameras]);

  const previewCamerasArray = useMemo(() => {
    if (isCamerasPreview(previewCameras)) {
      return previewCameras.feeds;
    }
    if(Array.isArray(previewCameras)) {
      return previewCameras;
    }
    return [];
  }, [previewCameras]);

  return (
    <MainCard title={translate("Cameras")} lastUpdatedTime={lastUpdatedTime}>
      <CamerasGrid>
        {loading && previewCamerasArray.length === 0 ? (
          <div style={{ justifySelf: "center" }}>
            <Ring color={"white"} size={30} />
          </div>
        ) : (
          previewCamerasArray.map((camera) => (
            <CardTemplate
              header={
                <Row>
                  <FullWidth>
                    <CardTitle>
                      {translateObject(camera.locationName)}
                    </CardTitle>
                  </FullWidth>
                </Row>
              }
              body={<PreviewImage previewImage={camera.previewImageURL} />}
              noPadding={true}
              key={camera.id}
            />
          ))
        )}
      </CamerasGrid>
    </MainCard>
  );
};

export default Preview;
