import React, { useMemo } from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import StatsPreview from "../../../../../interfaces/StatsPreview";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { formatMoney, formatMoneyMK } from "@appsinti/common-utils/textFormat";
import { CardTitleComponent, CustomRow } from "../../../styles";
import { useCurrency } from "@appsinti/currency-provider";

const Sales: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  const { currency } = useCurrency();

  const sales = useMemo(() => {
    const { salesData } = preview;
    const { conversions, currency: salesCurrency, total } = salesData;

    if (!currency) {
      return {
        totalSales: total.totalSales,
        salesPerGuest: total.salesPerGuest,
      };
    }

    const conversion = conversions && conversions[currency];
    if (conversion) {
      return {
        totalSales: conversion.total.totalSales,
        salesPerGuest: conversion.total.salesPerGuest,
      };
    }

    if (salesCurrency === currency) {
      return {
        totalSales: total.totalSales,
        salesPerGuest: total.salesPerGuest,
      };
    }

    return {
      totalSales: null,
      salesPerGuest: null,
    };
  }, [preview.salesData, currency]);

  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Total Sales"} />
          <CardTitleComponent title={"Sales/Guest"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
              {sales.totalSales !== null ? formatMoneyMK(sales.totalSales) : '-'}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
              {sales.salesPerGuest !== null ? formatMoney(sales.salesPerGuest) : '-'}
            </MetricData>
          </div>
        </CustomRow>
      }
    />
  );
};

export default Sales;
