import React, { PropsWithChildren } from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import StatsPreview from "../interfaces/StatsPreview";
import EarliestDate from "../interfaces/EarliestDate";
import { BACKEND } from "../resources/constants/urls";
import { useUpdate } from "@appsinti/trigger-update";
import {
  getPreviewMirror,
  updatePreviewMirror,
} from "@appsinti/preview-mirror";
import auth from "../resources/api/auth";
import { useDateProvider } from "@appsinti/date-provider";
import { format } from "date-fns";
import StatsDay from "../interfaces/StatsDay";
import CurrentTemperature from "@appsinti/common-utils/interfaces/weather/CurrentTemperature";

export type DataContextType = {
  loadingPreview: boolean;
  loadingDay: boolean;
  preview: StatsPreview | null;
  day: StatsDay | null;
  config: {
    appId: number;
  };
};

const DataContext = React.createContext<DataContextType>({
  loadingPreview: false,
  loadingDay: false,
  preview: null,
  day: null,
  config: {
    appId: 0,
  },
});

export const usePreviewData = () => React.useContext(DataContext);

export const PreviewDataProvider: React.FC<
  PropsWithChildren<{
    appId: number;
  }>
> = ({ appId, children }) => {
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
  const [loadingDay, setLoadingDay] = useState<boolean>(false);
  const [preview, setPreview] = useState<StatsPreview | null>(null);
  const { update, addLoadingState, resolveLoadingState } = useUpdate();
  const { registerMinimumDate, currentDate } = useDateProvider();
  const [day, setDay] = useState<StatsDay | null>(null);

  const registerDates = (dates: EarliestDate) => {
    const { minDate } = dates;

    if(minDate) registerMinimumDate(new Date(`${minDate}T04:00:00`));
  }

  const fetchPreviewData = async (appId: number): Promise<StatsPreview | null> => {
    const { Authorization } = auth;

    try {
      setLoadingPreview(true);
      addLoadingState(`stats-summary-frontend-${appId}`);

      const [current, dates] = await Promise.all([
        axios.get<StatsPreview>(`${BACKEND}/appsinti/stats-summary/data/preview`, {
          headers: { "app-id": appId, Authorization: Authorization },
        }),
        axios.get<EarliestDate>(`${BACKEND}/appsinti/stats-summary/metadata/earliest-date`, {
          headers: { "app-id": appId, Authorization: Authorization },
        }),
      ]);

      let currentWeatherRes: AxiosResponse<CurrentTemperature> | null = null;
      try {
        currentWeatherRes = await axios.get<CurrentTemperature>(
          `${BACKEND}/appsinti/weather/current`, { headers: { "app-id": appId, Authorization } }
        );
      } catch (error) {
        console.error(error);
      }

      registerDates(dates.data);

      const { data } = current;
      data.weatherData = currentWeatherRes?.data;
      data.lastUpdate = new Date().getTime();

      return data;
    } catch (error) {
      console.error(error);
    } finally {
      resolveLoadingState(`stats-summary-frontend-${appId}`);
      setLoadingPreview(false);
    }
    return null;
  };

  const storeLocalPreview = async () => {
    if(!appId) return;
    const previewData = await fetchPreviewData(appId);
    setPreview(previewData);
    if(previewData) {
      updatePreviewMirror(JSON.stringify(previewData), appId);
    }
  }

  const getLocalPreview = async (appId: number) => {
    const preview = getPreviewMirror(appId);
    if (!preview) { 
      storeLocalPreview();
    }
    else {
      setPreview(JSON.parse(preview) as StatsPreview);
    }
  };

  useEffect(() => {
    if (!appId) return;
    getLocalPreview(appId);
  }, [appId]);

  useEffect(() => {
    storeLocalPreview();
  }, [update]);

  const fetchDateDate = async (date: Date) => {
    setLoadingDay(true);
    try {
      const { Authorization } = auth;
      const dateRes = await axios.get<StatsDay>(`${BACKEND}/appsinti/stats-summary/data/${format(date, 'yyyy-MM-dd')}`, {
        headers: { "app-id": appId, Authorization: Authorization },
      });
      setDay(dateRes.data);
      console.log(dateRes.data);
    } catch (error) {
      console.error(error);
      setDay(null);
    } finally {
      setLoadingDay(false);
    }
  }

  useEffect(() => {
    if (!currentDate) {
      setDay(null);
      return;
    }
    fetchDateDate(currentDate);
  }, [currentDate]);

  return (
    <DataContext.Provider
      value={{
        loadingPreview,
        loadingDay,
        preview,
        day,
        config: { appId },
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
