import React, { useMemo } from "react";
import styled from "styled-components";
import { INPUTS } from "@etiquette-ui/colors";
import { FullWidth, MiddleWidth } from "@appsinti/ui-core/styles";
import StatsPreview from "@appsinti/stats-frontend/preview";
import Dashboard from "@appsinti/dashboard-frontend";
import BigBrother from "@appsinti/bigbrother-frontend/Preview";
import { useApps } from "pages/home/AppProvider";
import CustomLink from "@appsinti/ui-core/CustomLink";
import { default as DashboardPreviewConfig } from "@appsinti/dashboard-frontend/interfaces/DashboardPreviewConfig";
import UpdateAllButton from "../../../components/UpdateAllButton";
import { Size } from "@appsinti/common-utils/interfaces/IApp";

const WidgetMiddleWidth = styled(MiddleWidth)`
  padding: 2px;
  height: 50%;
`;

const WidgetFullWidth = styled(FullWidth)`
  padding: 2px;
  height: 50%;
`;

const Container = styled.div<{ bodyHeight: number }>`
  background-color: ${INPUTS};
  height: ${({ bodyHeight }) => bodyHeight}px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

const SizePicker: React.FC<{ size: Size; children: React.ReactNode }> = ({
  size,
  children,
}) => {
  switch (size) {
    case "half":
      return <WidgetFullWidth>{children}</WidgetFullWidth>;
    case "quarter":
      return <WidgetMiddleWidth>{children}</WidgetMiddleWidth>;
    default:
      return <div></div>;
  }
};

const ComponentPicker: React.FC<{
  appId: number;
  module: string;
  config: { [key: string]: any };
}> = ({ appId, module, config }) => {
  switch (module) {
    case "bigbrother":
      return <BigBrother appId={appId} />;
    case "stats":
      return <StatsPreview appId={appId} size={config.size} />;
    case "dashboard":
      return (
        <Dashboard appId={appId} config={config as DashboardPreviewConfig} />
      );
    default:
      return <div></div>;
  }
};

const Body: React.FC<{ bodyHeight: number }> = ({ bodyHeight }) => {
  const { gridApps } = useApps();

  const grid = useMemo(() => {
    return gridApps.map((app) => {
      if (!("size" in app.config)) return <div></div>;
      return (
        <SizePicker key={app.id} size={app.config.size}>
          <CustomLink to={`/app/${app.id}`}>
            <ComponentPicker
              appId={app.id}
              config={app.config}
              module={app.module}
            />
          </CustomLink>
        </SizePicker>
      );
    });
  }, [gridApps]);

  return (
    <Container bodyHeight={bodyHeight}>
      <UpdateAllButton />
      {grid}
    </Container>
  );
};

export default Body;
