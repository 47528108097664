import React from "react";
import { PreviewDataProvider } from "./data";
import Body from "./components/Body";
import EmptyCard from "./components/EmptyCard";
import CardContainer from "./containers/CardContainer";
import {
  HeightRestriction,
  Size,
  WidthRestriction,
} from "../interfaces/Restrictions";
import { SummaryApp } from "@appsinti/common-utils/interfaces/IApp";
import IGroup from "@appsinti/common-utils/interfaces/IGroup";

const Preview: React.FC<{
  app?: SummaryApp;
  groups: { [key: number]: IGroup };
  groupId: number;
  heightRestriction: HeightRestriction;
  widthRestriction: WidthRestriction;
}> = ({ app, groups, groupId, heightRestriction, widthRestriction }) => {
  if (!app) {
    return <EmptyCard groups={groups} groupId={groupId} widthRestriction={widthRestriction} />;
  }

  return (
    <PreviewDataProvider appId={app.id}>
      <CardContainer app={app} groups={groups} groupId={groupId} widthRestriction={widthRestriction} heightRestriction={heightRestriction}>
        <Body
          heightRestriction={heightRestriction}
          widthRestriction={widthRestriction}
        />
      </CardContainer>
    </PreviewDataProvider>
  );
};

export default Preview;
