import React, { useEffect, useState, PropsWithChildren } from "react";
import styled from "styled-components";
import { BG_DARK } from "@etiquette-ui/colors";

import useI18n from "@appsinti/i18n/useI18n";
import { useMetronome } from "@appsinti/metronome";

const Container = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 0px 0px 8px 8px;

  display: flex;
  flex-direction: column;

  background-color: #565c6a;
`;

const Header = styled.header`
  display: flex;
  padding: 3px 10px 3px 10px;
  border-radius: 0px 0px 8px 8px;
  background-color: ${BG_DARK};
  justify-content: space-between;
  align-items: center;
  gap: 3px;

  &.half-width {
    padding: 3px 6px 3px 6px;

    @media screen and (max-width: 380px) {
      padding: 2px 5px 2px 5px;
    }
  }
`;

const TitlesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;

  &.half-width {
    gap: 3px;
  }
`;

const Title = styled.span`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  flex-shrink: 0;
  flex-grow: 0;
  color: white;
  @media screen and (max-width: 380px) {
    font-size: 10px;
  }

  &.half-width {
    font-size: 12px;

    @media screen and (max-width: 380px) {
      font-size: 10px;
    }
  }
`;

const Subtitle = styled.span`
  margin: 0;
  padding: 0;
  font-size: 11px;
  font-weight: 400;
  color: white;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (max-width: 380px) {
    font-size: 8px;
  }

  &.half-width {
    font-size: 9px;

    @media screen and (max-width: 380px) {
      font-size: 7px;
    }
  }
`;

const Time = styled.span`
  font-size: 10px;
  text-align: right;
  font-weight: normal;
  color: white;
  flex-grow: 0;
  flex-shrink: 0;
  @media screen and (max-width: 380px) {
    font-size: 8px;
  }

  &.half-width {
    font-size: 8px;

    @media screen and (max-width: 380px) {
      font-size: 7px;
    }
  }
`;

const Body = styled.div`
  padding: 4px;
  flex: 1 1 auto;
`;

const MainCard: React.FC<
  PropsWithChildren<{
    className?: string;
    title: string;
    subtitle?: string;
    lastUpdatedTime?: Date | undefined;
    body?: React.ReactNode;
    halfWidth?: boolean;
  }>
> = ({
  className,
  title,
  subtitle,
  lastUpdatedTime,
  body,
  halfWidth = false,
  children,
}) => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const { registerCallback, unregisterCallback } = useMetronome();
  const { translate, formatDateDistance } = useI18n();

  useEffect(() => {
    const updateTimestamp = () => {
      setCurrentTime(new Date());
    };

    const key = registerCallback(updateTimestamp);

    return () => unregisterCallback(key);
  }, [registerCallback, unregisterCallback]);

  return (
    <Container className={className}>
      <Header className={halfWidth ? "half-width" : ""}>
        <TitlesContainer className={halfWidth ? "half-width" : ""}>
          <Title className={halfWidth ? "half-width" : ""}>{title}</Title>
          {subtitle && (
            <Subtitle className={halfWidth ? "half-width" : ""}>
              {subtitle}
            </Subtitle>
          )}
        </TitlesContainer>
        {!!lastUpdatedTime && (
          <Time className={halfWidth ? "half-width" : ""}>
            {translate("FORMAT_TIME_DISTANCE", {
              distance: formatDateDistance(lastUpdatedTime, currentTime),
            })}
          </Time>
        )}
      </Header>
      <Body className="body">
        {body && <>{body}</>}
        {children && <>{children}</>}
      </Body>
    </Container>
  );
};

export default MainCard;
