import React, { useMemo } from "react";
import { useAccess } from "providers/access";
import { SummaryApp } from "@appsinti/common-utils/interfaces/IApp";
import StatsSummaryPreview from "@appsinti/stats-summary-frontend/preview";
import {
  HeightRestriction,
  WidthRestriction,
} from "@appsinti/stats-summary-frontend/interfaces/Restrictions";

const ComponentPicker: React.FC<{ app?: SummaryApp; groupId: number }> = ({
  app,
  groupId,
}) => {
  const { groups } = useAccess();
  const heightRestriction: HeightRestriction = useMemo(() => {
    const groupsList = Object.values(groups);
    if (groupsList.length === 2) return "half";
    if (groupsList.length === 3) return "third";
    if (groupsList.length === 4) return "half";
    if (groupsList.length > 4) return "third";
    return "half";
  }, [groups]);

  const widthRestriction: WidthRestriction = useMemo(() => {
    const groupsList = Object.values(groups);
    if (groupsList.length < 4) return "full-width";
    return "half-width";
  }, [groups]);

  return (
    <StatsSummaryPreview
      app={app}
      groups={groups}
      groupId={groupId}
      heightRestriction={heightRestriction}
      widthRestriction={widthRestriction}
    />
  );
};

export default ComponentPicker;
