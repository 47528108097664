import React from "react";
import { AppProvider } from "./AppProvider";
import { Route, Routes } from "react-router-dom";
import Grid from "./pages/Grid";
import AppPage from "./pages/AppPage";
import UpdateProvider from "@appsinti/trigger-update";
import MetronomeProvider from "@appsinti/metronome";
import AlertServiceProvider from '@etiquette-ui/alert-dialog';
import Summary from "./pages/Summary";
import DateProvider from "@appsinti/date-provider";
import { CurrencyProvider } from "@appsinti/currency-provider";

export const HomePage: React.FC = () => {
  return (
    <AppProvider>
      <UpdateProvider>
        <MetronomeProvider>
          <CurrencyProvider>
          <AlertServiceProvider>
          <DateProvider>
            <Routes>
              <Route path={"/"} element={<Summary />} />
              <Route path={`/app/:appId/*`} element={<AppPage />} />
              <Route path={"/:groupId"} element={<Grid />} />
            </Routes>
          </DateProvider>
          </AlertServiceProvider>
          </CurrencyProvider>
        </MetronomeProvider>
      </UpdateProvider>
    </AppProvider>
  );
};
