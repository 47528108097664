import React from "react";
import styled from "styled-components";
import { BG } from "@etiquette-ui/colors";

import { useTemperature } from "@appsinti/temperature-provider";
import Icon from "@appsinti/ui-core/weather/Icon";

import useI18n from "@appsinti/i18n/useI18n";
import { useData } from "../data";

const Body = styled.div`
  width: 100%;
  padding: 12px 24px 24px 24px;
  overflow-x: scroll;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon-container {
    max-width: 4.5rem;
    flex: 0 0 45rem;
  }
  .main-info,
  .secondary-info {
    max-width: 40%;
    flex: 0 0 40%;
    padding-left: 1rem;
    color: ${BG};
  }

  .main-info {
    font-size: 4rem;
    font-weight: 700;
  }

  .secondary-info {
    font-size: 1.3rem;
    text-align: right;
  }
`;

const Current: React.FC = () => {
  const { current } = useData();
  const { temperatureObject } = useTemperature();
  const { translateObject } = useI18n();

  return current ? (
    <Body>
      <div className="icon-container">
        <Icon name={current.icon} />
      </div>
      <div className="main-info">{temperatureObject(current.temperature)}</div>
      <div className="secondary-info">
        {translateObject(current.summary)}
        <br />
        {temperatureObject(current.minTemperature)}/{" "}
        {temperatureObject(current.maxTemperature)}
      </div>
    </Body>
  ) : null;
};

export default Current;
