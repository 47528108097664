import React from "react";
import Icon from "@etiquette-ui/icons";
import { IconContainer } from "./cardStyles";

const ChevronRight: React.FC<{ className?: string; bigger?: boolean }> = ({ className, bigger = false }) => {
  return (
    <IconContainer className={`${bigger ? "bigger" : ""} ${className ?? ''}`}>
      <Icon
        icon={"chevron-right"}
        styles={{
          line: { fill: "none", strokeWidth: "1.5px", stroke: "black" },
          svg: { height: "100%", width: "100%" },
        }}
      />
    </IconContainer>
  );
};

export default ChevronRight;
