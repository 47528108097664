import React from 'react';
import styled from 'styled-components';

import { useTemperature } from '@appsinti/temperature-provider';
import Item from './Item';

import useI18n from '@appsinti/i18n/useI18n';
import { useData } from '../data';
import { dateFormat } from "@appsinti/common-utils";

const Container = styled.div`
  font-size: 1.4rem;
  padding: 1.6rem 1.2rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
`;

const Weekly: React.FC = () => {
  const { daily } = useData();
  const { temperatureObject } = useTemperature();
  const { translate } = useI18n();

  return (
    <Container>
      {daily && daily.map((dayData, index) => (
        <Item 
          key={index}
          title={index === 0 ? translate('Today') : dateFormat(dayData.date, 'EEEE')} 
          icon={dayData.icon} 
          isActive={index === 0 ? true : false}
          temperature={ temperatureObject(dayData.minTemperature) + "/ " + temperatureObject(dayData.maxTemperature) }
        />
      ))}
    </Container>
  )
}

export default Weekly;
