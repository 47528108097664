import React, { useEffect, useState } from 'react';
import { authAPI, getGroupInfo } from 'resources/api';
import IGroup from '@appsinti/common-utils/interfaces/IGroup';
import { ACCESS_USER_TYPES } from 'resources/constants/config';

const auth = localStorage.getItem("auth");

export type AccessContextType = {
  hasAccess: (access: string | null, type: string | null) => boolean;
  hasAccessInGroup: (groupId: number, access: string | null, type: string | null) => boolean;
  groupsIds: number[];
  groups: {[key: number]: IGroup};
};

const AccessContext = React.createContext<AccessContextType>({
  hasAccess: (a, t) => false,
  hasAccessInGroup: (g, a, t) => false,
  groupsIds: [],
  groups: {},
});

const AccessProvider: React.FC<{ children: any }> = ({ children }) => {
  const [accessList, setAccessList] = useState<string[]>([]);
  const [accessByGroup, setAccessByGroup] = useState<{[key:string]: string[]}>({});
  const [groupsIds, setGroupsIds] = useState<number[]>([]);
  const [groups, setGroups] = useState<{[key: number]: IGroup}>({});
  const [userType, setUserType] = useState<string>('');

  useEffect(()=>{
    if(auth === null) return;
    const parsedAuth = JSON.parse(auth);
    setAccessList(!!parsedAuth?.access ? parsedAuth.access ?? [] : []);
    setUserType(!!parsedAuth?.ofType ? parsedAuth.ofType ?? '' : '');
    setGroupsIds(!!parsedAuth?.groups ? parsedAuth.groups ?? [] : []);
  }, []);

  const loadAccess = async (groupsIds: number[]) => {
    const access: { [key:string]: string[] } = {};
    for(const id of groupsIds) {
      const res = await authAPI.getAll(id);
      access[+id] = res;
    }
    setAccessByGroup(access);
  }

  const loadGroups = async (groupsIds: number[]) => {
    const groups: { [key:number]: IGroup } = {};
    for(const id of groupsIds) {
      try {
        const res = await getGroupInfo(id);
        groups[+id] = res;
      } catch(e) {
        console.error(e);
      }
    }
    setGroups(groups);
  }

  useEffect(()=>{
    loadAccess(groupsIds);
    loadGroups(groupsIds);
  }, [groupsIds]);

  const hasAccess = (access: string | null, type: string  | null) => {
    return userType === ACCESS_USER_TYPES.ROOT || (!!access && accessList.includes(access)) || userType === type;
  }

  const hasAccessInGroup = (groupId: number, access: string | null, type: string | null) => {
    return userType === ACCESS_USER_TYPES.ROOT || (!!access && (accessByGroup[+groupId] ?? []).includes(access)) || userType === type;
  }

  return <AccessContext.Provider value={{hasAccess, hasAccessInGroup, groupsIds, groups}}>{children}</AccessContext.Provider>;
};

export const useAccess = () => React.useContext(AccessContext);

export default AccessProvider;
