import React from "react";
import { Route, Routes } from "react-router-dom";
import I8nProvider from "@appsinti/i18n/provider";
import { HomePage } from "pages/home";
import PrivateRoute from "containers/PrivateRoute";
import Login from "pages/Login";
import Logout from "pages/Logout";
import AccessProvider from "providers/access";
import TemperatureProvider from "@appsinti/temperature-provider";

function App() {
  return (
    <AccessProvider>
      <I8nProvider>
        <TemperatureProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/*" element={<PrivateRoute component={HomePage} />} />
          </Routes>
        </TemperatureProvider>
      </I8nProvider>
    </AccessProvider>
  );
}

export default App;
