import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import StatsPreview from "../interfaces/StatsPreview";
import { BACKEND } from "../resources/constants/urls";
import { useUpdate } from "@appsinti/trigger-update";
import { getPreviewMirror, updatePreviewMirror } from "../../../preview-mirror";
import auth from "../resources/api/auth";

export type DataContextType = {
  loading: boolean;
  preview: StatsPreview | null,
  config: {
    appId: number;
  };
};

const DataContext = React.createContext<DataContextType>({
  loading: false,
  preview: null,
  config: {
    appId: 0,
  },
});

export const usePreviewData = () => React.useContext(DataContext);
const { Authorization } = auth;

export const PreviewDataProvider: React.FC<{
  appId: number;
  children: any;
}> = ({ appId, children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [preview, setPreview] = useState<StatsPreview | null>(null);
  const { update, addLoadingState, resolveLoadingState } = useUpdate();

  const fetchData = async (appId: number): Promise<StatsPreview | null> => {
    try {
      setLoading(true);
      addLoadingState('stats-frontend');
      const current = await axios.get<StatsPreview>(`${BACKEND}/appsinti/stats/preview`, { headers: { "app-id": appId, Authorization } });
      const { data } = current;
      data.lastUpdate = new Date().getTime();

      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      resolveLoadingState('stats-frontend');
    }
    return null;
  };

  const storeLocalPreview = async () => {
      if(!appId) return;
      const previewData = await fetchData(appId);
      setPreview(previewData);
      if(previewData) {
        updatePreviewMirror(JSON.stringify(previewData), appId);
      }
  }

  const getLocalPreview = (appId: number) => {    
    const preview = getPreviewMirror(appId);
    if (!preview) { 
      storeLocalPreview();
    }
    else {
      setPreview(JSON.parse(preview) as StatsPreview);
    }
  }

  useEffect(() => {
    if (!appId) return;
    getLocalPreview(appId);
  }, [appId]);

  useEffect(() => {
    storeLocalPreview();
  }, [update]);

  return (
    <DataContext.Provider
      value={{
        loading,
        preview,
        config: { appId },
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
