import React from "react";
import { ADMIN_IMAGES_URL } from "@appsinti/common-utils/constants/urls";
import { TitleContainer, ContentContainer, ImageLarge } from "./cardStyles";
import IGroup from "@appsinti/common-utils/interfaces/IGroup";
import { WidthRestriction } from "../../interfaces/Restrictions";
import ChevronRight from "./CevronRight";

const EmptyCard: React.FC<{
  groups: { [key: number]: IGroup };
  groupId: number;
  widthRestriction: WidthRestriction;
}> = ({ groups, groupId, widthRestriction }) => {
  return (
    <>
      <TitleContainer className={widthRestriction}>
        <p>{groups[groupId].name}</p>
        <ChevronRight bigger={Object.values(groups).length <= 3} />
      </TitleContainer>
      <ContentContainer>
        <ImageLarge src={`${ADMIN_IMAGES_URL}/${groups[groupId].imageLight}`} />
      </ContentContainer>
    </>
  );
};

export default EmptyCard;
