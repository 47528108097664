import React, { useMemo } from "react";
import { usePreviewData } from "../data";
import styled from "styled-components";
import MainCard from "@appsinti/ui-core/MainCard";
import PreviewSection from "./PreviewSection";
import {
  HeightRestriction,
  WidthRestriction,
} from "../../interfaces/Restrictions";
import DaySection from "./DaySection";
import { useDateProvider } from "@appsinti/date-provider";
import { useCurrencySelector } from "@appsinti/currency-provider";
import StatsPreview from "../../interfaces/StatsPreview";
import StatsDay from "../../interfaces/StatsDay";

const CustomMainCard = styled(MainCard)`
  > .body {
    gap: 5px;
    padding: 5px;
  }
`;

const Preview: React.FC<{
  heightRestriction: HeightRestriction;
  widthRestriction: WidthRestriction;
  preview: StatsPreview | null;
  loading: boolean;
  lastUpdatedTime?: Date;
}> = ({ heightRestriction, widthRestriction, preview, loading, lastUpdatedTime }) => {
  const { currency } = useCurrencySelector();

  const subtitle = useMemo(() => {
    if (
      (currency === undefined || currency === null) &&
      preview?.salesData.currency
    ) {
      return `(${preview.salesData.currency})`;
    }
    if (currency === undefined) {
      return undefined;
    }
    if (
      currency &&
      preview?.salesData.conversions &&
      !!preview?.salesData.currency &&
      preview?.salesData.currency !== currency &&
      preview?.salesData.conversions[currency] &&
      preview?.salesData.conversions[currency].rate
    ) {
      return `(1 ${preview.salesData.currency} = ${preview.salesData.conversions[currency].rate} ${currency})`;
    }
    if (preview?.salesData.currency && (currency === null || currency === preview.salesData.currency)) {
      return `(${preview.salesData.currency})`;
    }
    return undefined;
  }, [currency, preview]);

  return (
    <CustomMainCard title={"Sales"} subtitle={subtitle} lastUpdatedTime={lastUpdatedTime} halfWidth={widthRestriction === "half-width"}>
      <PreviewSection
        preview={preview}
        loading={loading}
        heightRestriction={heightRestriction}
        widthRestriction={widthRestriction}
      />
    </CustomMainCard>
  );
};

const Day: React.FC<{
  heightRestriction: HeightRestriction;
  widthRestriction: WidthRestriction;
  day: StatsDay | null;
  loading: boolean;
  lastUpdatedTime?: Date;
}> = ({ heightRestriction, widthRestriction, day, loading, lastUpdatedTime }) => {
  return (
    <CustomMainCard title={"Sales"} lastUpdatedTime={lastUpdatedTime} halfWidth={widthRestriction === "half-width"}>
      <DaySection
        day={day}
        loading={loading}
        heightRestriction={heightRestriction}
        widthRestriction={widthRestriction}
      />
    </CustomMainCard>
  );
};

const Body: React.FC<{
  heightRestriction: HeightRestriction;
  widthRestriction: WidthRestriction;
}> = ({ heightRestriction, widthRestriction }) => {
  const { preview, day, loadingPreview, loadingDay } = usePreviewData();
  const { currentDate } = useDateProvider();

  const lastUpdatedTime = useMemo(() => {
    if (preview?.lastUpdate) {
      return new Date(preview.lastUpdate);
    }
  }, [preview]);

  return (
    <>
      {currentDate ? (
        <Day
          day={day}
          loading={loadingDay}
          heightRestriction={heightRestriction}
          widthRestriction={widthRestriction}
          lastUpdatedTime={lastUpdatedTime}
        />
      ) : (
        <Preview
          preview={preview}
          loading={loadingPreview}
          heightRestriction={heightRestriction}
          widthRestriction={widthRestriction}
          lastUpdatedTime={lastUpdatedTime}
        />
      )}
    </>
  );
};

export default Body;
