import React, { useEffect, useState } from "react";

import IconTextItem from "@appsinti/ui-core/IconTextItem";

import WeatherIcon from "./resources/img/weather-icon.svg";

import useI18n from "@appsinti/i18n/useI18n";

import HomeComponent from "./home";
import axios from "axios";
import CurrentTemperature from "@appsinti/common-utils/interfaces/weather/CurrentTemperature";

const Home = HomeComponent;

import auth from "./resources/api/auth";
import { BACKEND } from "@appsinti/common-utils/constants/urls";
import { ca } from "date-fns/locale";
import { useTemperature } from "@appsinti/temperature-provider";

const { Authorization } = auth;

export const Preview: React.FC<{ appId: number }> = ({ appId }) => {
  const { translate } = useI18n();
  const { temperatureObject } = useTemperature();
  const [currentTemperature, setCurrentTemperature] =
    useState<CurrentTemperature | null>(null);

  const fetchData = async () => {
    try {
      const current = await axios.get<CurrentTemperature>(
        `${BACKEND}/appsinti/weather/current`,
        { headers: { "app-id": appId, Authorization } }
      );
      setCurrentTemperature(current.data);
    } catch (e) {
      console.error(e);
      setCurrentTemperature(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <IconTextItem
      icon={WeatherIcon}
      isImage={true}
      body={
        currentTemperature ? (
          <>
            {translate("Weather")} (
            {temperatureObject(currentTemperature.temperature)})
          </>
        ) : (
          <>{translate("Weather")}</>
        )
      }
    />
  );
};

export default Home;
