import { useAccess } from 'providers/access';
import React, { useCallback, useEffect, useState } from 'react';
import { getApps } from 'resources/api';
import IApp, { ScreenApp, SummaryApp } from '@appsinti/common-utils/interfaces/IApp';

export type AppContextType = {
    apps: {[key: number | string]: IApp[] } | null;
    gridApps: ScreenApp[];
    summaryApps: SummaryApp[] | null;
    selectGroup: (groupdId: string | null) => void
};

const AppContext = React.createContext<AppContextType>({
    apps: null,
    gridApps: [],
    summaryApps: null,
    selectGroup: () => {}
});

const moduleSizeValue: { [key: string]: number } = {
    eight: 0,
    quarter: 1,
    half: 2,
  };  

export const useApps = () => React.useContext(AppContext);

export const AppProvider: React.FC<{ children: any }> = ({ children }) => {
    const [apps, setApps] = useState<{[key: number | string]: IApp[] } | null>(null);
    const { groupsIds } = useAccess();
    const [summaryApps, setSummaryApps] = useState<SummaryApp[] | null>(null);
    const [gridApps, setGridApps] = useState<ScreenApp[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

    useEffect(()=>{
        if(groupsIds.length === 0) return;
        getAppsFromIds(groupsIds);
    }, [groupsIds]);

    const getAppsFromIds = async (groupsIds: number[]) => {
        const allApps: {[key: number | string]: IApp[] } = {};
        for(const id of groupsIds) {
            const apps = await getApps(id);
            if(apps.length > 0) {
                allApps[+id] = apps;
            }
        }

        let summaryApps: SummaryApp[] = [];
        if(groupsIds.length > 1) {
            const apps = await getApps(null);
            for(const app of apps) {
                if(app.blueprint.type === "summary") {
                    summaryApps.push(app as SummaryApp);
                }
            }
        }
        setSummaryApps(summaryApps);

        setApps(allApps);
    }

    const selectGroup = useCallback((groupdId: string | null) => {
        setSelectedGroup(groupdId);
    }, []);

    useEffect(()=>{
        if(apps === null) return;
        if(!selectedGroup) { 
            setGridApps([]);
            return;
        }

        const selectedApps = apps[selectedGroup];
        if(!selectedApps) return;

        const gridApps: ScreenApp[] = [];
        for(const app of selectedApps) {
            if(app.blueprint.type === "screen") {
                gridApps.push(app as ScreenApp);
            }
        }

        setGridApps(gridApps.sort((a, b) => {
              if ("size" in a.config && "size" in b.config) {
                const aSize = a.config.size;
                const bSize = b.config.size;
      
                const aValue = moduleSizeValue[aSize];
                const bValue = moduleSizeValue[bSize];
                return aValue - bValue;
              }
      
              return 0;
            }));
    }, [selectedGroup, apps]);

    return <AppContext.Provider value={{ apps, selectGroup, gridApps, summaryApps }}>{children}</AppContext.Provider>;
};
