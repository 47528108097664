import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getGroupInfo } from "resources/api";
import IGroup from "@appsinti/common-utils/interfaces/IGroup";
import {
  SECONDARY_DARK,
  TEXT_PLACEHOLDER,
} from "@etiquette-ui/colors";
import { useApps } from "pages/home/AppProvider";
import { ChevronDownIcon, ChevronUpIcon } from "@etiquette-ui/icons";
import { useNavigate, useParams } from "react-router-dom";

const Container = styled.div`
  max-width: 54%;
  flex: 0 0 60%;
  margin: auto;
  padding: 0 20px;
  font-size: 1.4rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Menu = styled.div<{ headerHeight: number | undefined }>`
  padding: 10px;
  background-color: white;
  position: absolute;
  top: calc(
    ${({ headerHeight }) => (headerHeight ? headerHeight : "50")}px - 22px
  );
  box-shadow: 0px 8px 24px #0332693d;
  border-radius: 8px;
  z-index: 1;
`;

const Item = styled.p<{ active: boolean }>`
  margin: 0;
  padding: 10px 5px;
  color: ${TEXT_PLACEHOLDER};
  ${({ active }) => (active ? `background-color: ${SECONDARY_DARK};` : "")}
`;

const Selector: React.FC<{
  list: { [key: string]: IGroup };
  open: boolean;
  closeMenu: () => void;
  headerHeight: number | undefined;
}> = ({ list, open, headerHeight, closeMenu }) => {
  const { groupId } = useParams<{ groupId: string }>();
  const navigate = useNavigate();

  return groupId ? (
    <>
      <span>{list[groupId].name}</span>
      {open && (
        <Menu headerHeight={headerHeight}>
          <Item
            active={false}
            onClick={() => {
              closeMenu();
              navigate(`/`);
            }}
          >
            Summary
          </Item>
          {Object.values(list)
            .sort((a, b) => a.id - b.id)
            .map((value) => (
              <Item
                key={value.id}
                active={+groupId === value.id}
                onClick={() => {
                  //selectGroup(value.id === 0 ? SUMMARY_KEY : value.id);
                  closeMenu();
                  navigate(`/${value.id}`);
                }}
              >
                {value.name}
              </Item>
            ))}
        </Menu>
      )}
      <div style={{ width: "20px" }}>
        {open ? <ChevronUpIcon styles={{}} /> : <ChevronDownIcon styles={{}} />}
      </div>
    </>
  ) : (
    <span></span>
  );
};

export const DependencySelector: React.FC<{
  headerHeight: number | undefined;
}> = ({ headerHeight }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dependencyList, setDependencyList] = useState<{
    [key: string]: IGroup;
  }>({});
  const [showSelector, setShowSelector] = useState<boolean>(false);
  const { apps } = useApps();

  useEffect(() => {
    if (apps === null) return;
    const ids = Object.keys(apps);
    if (ids.length === 0) return;
    getDetailsFromIds(ids);
  }, [apps]);

  const getDetailsFromIds = async (groupsIds: (number | string)[]) => {
    const groups: { [key: string | number]: IGroup } = {};
    for (const id of groupsIds) {
      if (isNaN(+id)) continue;
      const group = await getGroupInfo(+id);
      groups[id] = group;
    }
    setDependencyList(groups);
  };

  useEffect(() => {
    console.log("dependencyList", dependencyList);
    setShowSelector(Object.keys(dependencyList).length > 1);
  }, [dependencyList]);

  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <Container onClick={() => setOpen(!open)}>
      {showSelector ? (
        <Selector
          list={dependencyList}
          open={open}
          headerHeight={headerHeight}
          closeMenu={closeMenu}
        />
      ) : Object.keys(dependencyList).length > 0 ? (
        <span style={{ textAlign: "center" }}>
          {Object.values(dependencyList)[0].name}
        </span>
      ) : (
        <></>
      )}
    </Container>
  );
};
