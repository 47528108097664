import CardTemplate from "@appsinti/ui-core/CardTemplate";
import CustomLink from "@appsinti/ui-core/CustomLink";
import { INPUTS } from "@etiquette-ui/colors";
import styled from "styled-components";

export const BodyContainer = styled.div<{ bodyHeight: number }>`
  background-color: ${INPUTS};
  height: ${({ bodyHeight }) => bodyHeight}px;
  max-height: ${({ bodyHeight }) => bodyHeight}px;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px;

  &.row {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const GroupLink = styled(CustomLink)`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const GroupContainer = styled(CardTemplate)<{heightPercentage: number;}>`
  height: calc(${({ heightPercentage }) => heightPercentage}% - 2px);
  max-height: calc(${({ heightPercentage }) => heightPercentage}% - 2px);
  &.full-width {
    max-width: 100%;
    width: 100%;
    flex: 0 0 calc(${({ heightPercentage }) => heightPercentage}% - 2px);
  }

  &.half-width {
    max-width: 50%;
    flex: 0 0 calc(50% - 5px);
  }

  .body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const ContentContainer = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  height: 100%;
`;

export const ImageLarge = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;

  p {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    flex-shrink: 1;
  }

  line {
    fill: none;
    stroke-width: 1.5px;
    stroke: black;
  }

  svg {
    height: 28px;
    width: 28px;
  }

  &.half-width {
    p {
      font-size: 1.2rem;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }

  @media screen and (max-height: 750px) {
    padding: 4px;
  }
`;

export const TitleWithImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;

  .image {
    height: 30px;
    width: 30px;
    overflow: hidden;
    border-radius: 18px;
    flex: 0 0 30px;
  }

  &.half-width {
    gap: 3px;
    .image {
      height: 22px;
      width: 22px;
      flex-basis: 22px;
    }
  }

  @media screen and (max-height: 750px) {
    .image {
      height: 23px;
      width: 23px;
      flex-basis: 23px;
      border-radius: 18px;
    }

    &.half-width {
      .image {
        height: 16px;
        width: 16px;
        flex-basis: 16px;
      }
    }
  }
`;

export const IconContainer = styled.div`
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 20px;

  &.half-width {
    height: 16px;
    width: 16px;
  }

  &.third {
    height: 16px;
    width: 16px;
  }

  .bigger {
    height: 28px;
    width: 28px;
    flex-basis: 28px;
  }

  @media screen and (max-height: 750px) {
    height: 17px;
    width: 17px;
    flex-basis: 17px;
    .bigger {
      height: 20px;
      width: 20px;
      flex-basis: 20px;
    }
  }
`;

export const WeatherContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  flex-grow: 0;

  p {
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    flex-grow: 0;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 0px;
    flex-shrink: 0;
    flex-grow: 0;

    p {
      font-size: 1rem;
      font-weight: 500;
      white-space: nowrap;
      flex-shrink: 0;
      flex-grow: 0;
      margin: 0;
      padding: 0;
      line-height: 1rem;
    }
  }

  &.half-width {
    gap: 2px;

    &.third {
      gap: 1px;
      p {
        font-size: 1.2rem;
      }
    }

    p {
      font-size: 1.3rem;
    }
  }

  @media screen and (max-height: 750px) {
    p {
      font-size: 1.7rem;
    }

    div {
      p {
        font-size: 0.8rem;
        line-height: 0.8rem;
      }
    }

    &.half-width {
      &.third {
        p {
          font-size: 1rem;
        }
      }

      p {
        font-size: 1.4rem;
      }
    }
  }
`;

export const WeatherIconContainer = styled.div`
  width: 20px;
  &.half-width {
    width: 14px;

    &.third {
      width: 13px;
    }
  }
`;
