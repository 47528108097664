import React, { useMemo } from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { CardTitleComponent, CustomRow } from "../../../styles";
import { formatMoneyMK, formatPercentage } from "@appsinti/common-utils/textFormat";
import StatsPreview from "../../../../../interfaces/StatsPreview";
import { useCurrency } from "@appsinti/currency-provider";

const ClosedSalesCash: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  const { currency } = useCurrency();
  
    const sales = useMemo(() => {
      const { salesData } = preview;
      const { conversions, currency: salesCurrency, total } = salesData;
  
      if (!currency) {
        return {
          closedSales: total.closedSales,
        };
      }
  
      const conversion = conversions && conversions[currency];
      if (conversion) {
        return {
          closedSales: conversion.total.closedSales,
        };
      }
  
      if (salesCurrency === currency) {
        return {
          closedSales: total.closedSales,
        };
      }
  
      return {
        closedSales: null,
        salesPerGuest: null,
      };
    }, [preview.salesData, currency]);

  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Closed Sales"} />
          <CardTitleComponent title={"Cash %"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
              {sales.closedSales !== null ? formatMoneyMK(sales.closedSales) : "-"}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
              {formatPercentage(preview.salesData.total.cash, { decimalCount: 1 })}
            </MetricData>
          </div>
        </CustomRow>
      }
    />
  );
};

export default ClosedSalesCash;
