import IVideo from "./IVideo";

export default interface CamerasPreview {
  feeds: IVideo[];
  lastUpdate?: number;
}


export function isCamerasPreview(obj: any): obj is CamerasPreview {
    return (
        obj != null &&
        typeof obj === 'object' &&
        Array.isArray(obj.feeds) &&
        (obj.lastUpdate === undefined || typeof obj.lastUpdate === 'number')
    );
}