import Temperature from '@appsinti/common-utils/interfaces/weather/Temperature';
import React, { useState, useEffect } from 'react';

export type TemperatureContextType = {
  selectedTemperatureUnit: keyof Temperature;
  setSelectedTemperatureUnit: (temperatureUnit: string) => void;
  temperatureObject: (value: Temperature ) => string;
};

export const TemperatureContext = React.createContext<TemperatureContextType>({
  selectedTemperatureUnit: 'F',
  setSelectedTemperatureUnit: (temperatureUnit) => console.log(temperatureUnit),
  temperatureObject: (value) => `**${value}**`,
});

const processTremperature = (value: Temperature, temperatureUnitKey: keyof Temperature): string => {
  return value[temperatureUnitKey];
}

const TemperatureProvider: React.FC<{ children: any }> = ({ children }) => {
    const [selectedTemperatureUnit, setSelectedTemperatureUnit] = useState<string>('F');

    useEffect(() => {
        const temperatureUnitFromStorage = localStorage.getItem('temperature-unit-code');
        const initTemperatureUnit = temperatureUnitFromStorage ? temperatureUnitFromStorage : 'F';
        setSelectedTemperatureUnit(initTemperatureUnit);
    }, []);

    const values: TemperatureContextType = {
        selectedTemperatureUnit: selectedTemperatureUnit,
        setSelectedTemperatureUnit: (temperatureUnit: string) => {
            setSelectedTemperatureUnit(temperatureUnit);
            localStorage.setItem('temperature-unit-code', temperatureUnit);
        },
        temperatureObject: (value: Temperature ) => processTremperature(value, selectedTemperatureUnit),
    };

    return (<TemperatureContext.Provider value={values}> {children} </TemperatureContext.Provider>);
};

export const useTemperature: () => TemperatureContextType = () => {
  const { temperatureObject, selectedTemperatureUnit, setSelectedTemperatureUnit } = React.useContext(TemperatureContext);

  return { temperatureObject, selectedTemperatureUnit, setSelectedTemperatureUnit };
}

export default TemperatureProvider;