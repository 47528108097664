import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import Container from './Container';
import useI18n from '@appsinti/i18n/useI18n';
import { useMetronome } from '@appsinti/metronome';
import { ArrowLeftIcon } from "@etiquette-ui/icons";

const CustomContainer = styled(Container)`
  height: 5.6rem;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 20;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Icon = styled(Link)`
  max-width: 20px;
  width: 20px;
  display: block;

  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
`;

const Title = styled.p`
  flex: 1 1 auto;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

const TrailingContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
`;

const Time = styled.p`
  margin: 0;
  padding: 0;
  text-align: end;
  font-size: 12px;
  font-weight: 500;
`;

type Header = {
  title: string;
  lastUpdated?: Date|undefined;
  icon?: string | undefined | React.ReactNode;
  backButtonLocation?: string;
  timeFormat?: 'short' | 'long';
}
const Header: React.FC<Header> = ({title, lastUpdated, icon, backButtonLocation = '/', timeFormat = 'short' }) => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const { registerCallback, unregisterCallback } = useMetronome();
  const { translate, formatDate, formatDateDistance } = useI18n();

  useEffect(() => {
    const updateTimestamp = () => {
      setCurrentTime(new Date());
    };

    const key = registerCallback(updateTimestamp);

    return () => unregisterCallback(key);
  }, [registerCallback, unregisterCallback]);

  return (
    <CustomContainer>
      <TitleContainer>
        <Icon to={backButtonLocation}>
          {!icon && <ArrowLeftIcon styles={{}} />}
          {!! icon && (typeof icon === 'string' ? <img src={icon} alt="Appsinti" /> : icon)}
        </Icon>
        <Title>{title}</Title>
      </TitleContainer>
      <TrailingContainer>
        {!!lastUpdated && (<Time>{translate(timeFormat === 'short' ? 'FORMAT_TIME_DISTANCE' : 'FORMAT_TIME_DISTANCE_EXTENDED', { date: formatDate(lastUpdated, "E, MMM d"), distance: formatDateDistance(lastUpdated, currentTime) })}</Time>)}
      </TrailingContainer>
    </CustomContainer>
  )
}

export default Header;
