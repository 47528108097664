import { format, subDays, add, isAfter, Locale, formatDistanceStrict, differenceInMinutes, intervalToDuration } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { capitalize } from './text';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const DISPLAY_DATE_FORMAT = 'd / MMMM / yyyy';

export enum Language {
    English = 'en',
    Spanish = 'es',
}

const Locales: { [key in Language]: Locale } = {
    [Language.English]: enUS,
    [Language.Spanish]: es, 
}

export const dateFormat: (date: string | Date, FORMAT?: string, selectedLang?: Language) => string = (date, FORMAT = DATE_FORMAT, selectedLang = Language.English) => {
    const dateFormatted = format(!!date ? new Date(date) : new Date(), FORMAT, {
        locale: Locales[selectedLang],
    });

    return dateFormatted;
};

export const getDisplayDate: (date: string | Date, FORMAT?: string, selectedLang?: Language) => string = (date, format = DISPLAY_DATE_FORMAT, selectedLang = Language.English) => {
    const dateFormatted = dateFormat(new Date(date), format, selectedLang);

    return capitalize(dateFormatted);
};

export const formatDateDistance: (dateStart: string | Date, dateEnd: string | Date, selectedLang?: Language) => string = (
    dateStart,
    dateEnd,
    selectedLang = Language.English
) => {
    const start = new Date(dateStart);
    const end = new Date(dateEnd);
    const diffInMinutes = differenceInMinutes(end, start);

    if (diffInMinutes < 60) {
        const duration = intervalToDuration({ start, end });
        return `${duration.minutes} min.`;
    }

    return formatDistanceStrict(start, end, {
        locale: Locales[selectedLang],
    });
}

export const addDays = (date, days) => {
    const dateObj = !!date ? new Date(date) : new Date();
    return add(dateObj, { days });
};

export const removeDays = (date, days) => {
    const dateObj = !!date ? new Date(date) : new Date();
    return subDays(dateObj, days);
};

export const isDateAfter = (date, dateToCompare) => {
    return isAfter(new Date(date), new Date(dateToCompare));
}