import React, { useMemo } from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import StatsPreview from "../../../../../interfaces/StatsPreview";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { formatMoneyMK } from "@appsinti/common-utils/textFormat";
import { CardTitleComponent, CustomRow } from "../../../styles";
import { useCurrency } from "@appsinti/currency-provider";

const SalesRow: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  const { currency } = useCurrency();

  const sales = useMemo(() => {
    const { salesData } = preview;
    const {
      conversions,
      currency: salesCurrency,
      total,
      lunch,
      dinner,
    } = salesData;

    if(!currency) {
      return {
        totalSales: total.totalSales,
        lunchTotalSales: lunch?.totalSales ?? null,
        dinnerTotalSales: dinner?.totalSales ?? null,
      };
    }

    const conversion = conversions && conversions[currency];
    if (conversion) {
      return {
        totalSales: conversion.total.totalSales,
        lunchTotalSales: conversion.lunch?.totalSales ?? null,
        dinnerTotalSales: conversion.dinner?.totalSales ?? null,
      };
    }

    if (salesCurrency === currency) {
      return {
        totalSales: total.totalSales,
        lunchTotalSales: lunch?.totalSales ?? null,
        dinnerTotalSales: dinner?.totalSales ?? null,
      };
    }

    return {
      totalSales: null,
      lunchTotalSales: null,
      dinnerTotalSales: null,
    };
  }, [preview.salesData, currency]);

  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Total Sales"} />
          <CardTitleComponent title={"Lunch"} />
          <CardTitleComponent title={"Dinner"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
              {sales.totalSales !== null
                ? formatMoneyMK(sales.totalSales)
                : "-"}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
              {sales.lunchTotalSales !== null
                ? formatMoneyMK(sales.lunchTotalSales)
                : "-"}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
              {sales.dinnerTotalSales !== null
                ? formatMoneyMK(sales.dinnerTotalSales)
                : "-"}
            </MetricData>
          </div>
        </CustomRow>
      }
    />
  );
};

export default SalesRow;
