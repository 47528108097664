import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import StatsPreview from "../../../../../interfaces/StatsPreview";
import { CardTitleComponent, CustomRow } from "../../../styles";
import { formatPercentage } from "@appsinti/common-utils";
import { WRONG_DARK } from "@etiquette-ui/colors";

const CancellationCourtesy: React.FC<{ preview: StatsPreview }> = ({
  preview,
}) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Cancellations"} />
          <CardTitleComponent title={"Courtesy"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={WRONG_DARK}>
              {formatPercentage(preview.salesData.total.cancellation, 1)}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={WRONG_DARK}>
              {formatPercentage(preview.salesData.total.courtesy, 1)}
            </MetricData>
          </div>
        </CustomRow>
      }
    />
  );
};

export default CancellationCourtesy;
