import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  PropsWithChildren,
  useMemo,
} from "react";
import { SUPPORTED_CURRENCIES, DEFAULT_CURRENCY, CURRENCY_ENABLED } from "@appsinti/common-utils/constants/config";

const CURRENCIES_LIST: string[] = SUPPORTED_CURRENCIES?.split("|") || [];
const IS_CURRENCY_ENABLED = CURRENCIES_LIST.length > 1 && CURRENCY_ENABLED;

const getCurrencyFromStorage = (): string | null | undefined => {
    try {
        const curr = localStorage.getItem('currency-code');
        if (curr === null || typeof curr !== 'string') {
            return DEFAULT_CURRENCY;
        }
        if(curr === 'null') {
            return null;
        }
        return curr;
    } catch {
        return DEFAULT_CURRENCY;
    }
};

const saveLanguageToStorage = (curr: string | null) => {
    try {
        if(curr === null) {
            localStorage.setItem('currency-code', 'null');
        } else {
            localStorage.setItem('currency-code', curr);
        }
    } catch {
        console.error('Local Storage unavailable');
    }
};

interface CurrencyContextProps {
  currency: null | string | undefined;
  setCurrency: (currency: null | string) => void;
  supportedCurrencies: string[];
  isVisible: boolean;
  currencyEnabled: boolean;
  _addSubscriber: () => void;
  _removeSubscriber: () => void;
}

const CurrencyContext = createContext<CurrencyContextProps | undefined>(
  undefined
);

export const CurrencyProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [currency, setCurrency] = useState<string | undefined | null>(getCurrencyFromStorage);

  const [subscriberCount, setSubscriberCount] = useState<number>(0);

  const _addSubscriber = useCallback(() => {
    setSubscriberCount((count) => count + 1);
  }, []);

  const _removeSubscriber = useCallback(() => {
    setSubscriberCount((count) => count - 1);
  }, []);

  const isVisible = useMemo(() => subscriberCount > 0 && IS_CURRENCY_ENABLED, [subscriberCount]);

  return (
    <CurrencyContext.Provider
      value={{
        currency: IS_CURRENCY_ENABLED ? currency : undefined,
        currencyEnabled: IS_CURRENCY_ENABLED,
        setCurrency: (curr: string | null) => {
          setCurrency(curr);
          saveLanguageToStorage(curr);
        },
        supportedCurrencies: CURRENCIES_LIST,
        isVisible,
        _addSubscriber,
        _removeSubscriber,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error("useCurrency must be used within a CurrencyProvider");
  }
  useEffect(() => {
    context._addSubscriber();
    return () => {
      context._removeSubscriber();
    };
  }, [context]);

  return {
    supportedCurrencies: context.supportedCurrencies,
    currency: context.currency,
    currencyEnabled: context.currencyEnabled,
  };
};

export const useCurrencySelector = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error(
      "useCurrencySelector must be used within a CurrencyProvider"
    );
  }
  return {
    supportedCurrencies: context.supportedCurrencies,
    currency: context.currency,
    setCurrency: context.setCurrency,
    isVisible: context.isVisible,
    currencyEnabled: context.currencyEnabled,
  };
};
