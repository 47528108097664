import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import { default as MainHeaderApp } from "@appsinti/ui-core/header";
import { APP_PUBLIC_NAME } from "@appsinti/stats-frontend/constants/config";
import useI18n from "@appsinti/i18n/useI18n";
import { useData } from "../data";

const BodyContainer = styled.div`
  padding: 1.5rem;
  padding-top: 5rem;
`;

const Body: React.FC<PropsWithChildren<{ style?: React.CSSProperties }>> = ({ style, children }) => {
  const { translateObject } = useI18n();
  const { lastUpdate, groupId } = useData();

  return (
    <>
      <MainHeaderApp
        title={translateObject(APP_PUBLIC_NAME)}
        lastUpdated={lastUpdate}
        backButtonLocation={groupId ? `/${groupId}` : '..'}
        timeFormat="long"
      />
      <Navbar />
      <BodyContainer style={style}>{children}</BodyContainer>
    </>
  );
};

export default Body;
