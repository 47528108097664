const ES: { [key: string]: string } = {

    //Core frontend
    'Logout': 'Cerrar Sesión',
    'min old': 'minutos',
    'FORMAT_TIME_DISTANCE': 'Hace %distance%',
    'FORMAT_TIME_DISTANCE_EXTENDED': 'Actualizado: %date% (hace %distance%)',
    'TODAY': 'Hoy',

    //Weather
    'Weather': 'Clima',
    'Today': 'Hoy',

    //Stats
    'Total sales': 'Total ventas',
    'Sales/ Guest':  'Ventas/ Invitados',
    'Closed sales': 'Ventas terminadas',
    'Cash %': 'Dinero %',
    'Now/Total': 'Total',
    'Reserve': 'Reservas',
    'Cancellations': 'Cancelaciones',
    'Courtesy': 'Cortesías',
    'Month': 'Mes',
    'Week': 'Semana',
    'Customer Count': 'Comensales',
    'Edit Special Days': 'Editar Días Especiales',

    //Big brother
    'camera': 'Cámara',
    'Cameras': 'Cámaras',
    'Viewing': 'Visualizando',
    'New camera': 'Nueva cámara',
    'Top camera': 'Cámara de arriba',
    'Bottom camera': 'Cámara de abajo',
    'Update Camera': 'Actualizar cámara',
    'Camera Preview': 'Iniciado cámara',
    'Action required': 'Acción necesaria',
    'Click on Continue button to complete the renamed': 'Selecciona el botón continuar para completar el cambio de nombre',
    'You need to highlight other camera to replace this one': 'Necesitas establecer al menos una cámara para el tablero principal. Revisa de nuevo la lista.',
    'Click on Update to set the camera as assigned to appsinti home': 'Clic en actualizar para asignar esta cámara en la pantalla principal.',
    'Click on Update to delete the camera assigned to appsinti home': 'Clic en actualizar para eliminar esta cámara asignada en la pantalla principal.',
    'You will change the camera view from the Appsinti home. Choose one below to replace:': 'Para elegir y mostrar este video en la pantalla principal, debes seleccionar cuál de los siguientes deseas remplazar.',

    //Buttons and inputs
    'Add': 'Agregar',
    'Delete': 'Eliminar',
    'Save': 'Guardar',
    'Back': 'Regresar',
    'Close': 'Cerrar',
    'Cancel': 'Cancelar',
    'Update': 'Continuar',
    'Continue': 'Continuar',
    'New name': 'Nuevo nombre',
    'Current name':'Nombre actual',
    'Select...': 'Elige...',
    'Back and edit': 'Regresar y editar',
    'Select and change': 'Elegir y asignar',
    'Appsinti home cameras': 'Appsinti, cámaras destacadas',

    //Messages
    'Name must be in a valid format': 'El nuevo nombre debe tener un formato válido',
    'The upgrade was successful': 'La actualización terminó correctamente',
    'The upgrade failed, try again later': 'La actualización falló, intentalo de nuevo más tarde',
    'Loading...': 'Cargando...',
};

export default ES;
