import React, { useMemo } from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { CardTitleComponent, CustomRow, MainColumn } from "../../../styles";
import { formatMoney } from "@appsinti/common-utils/textFormat";
import StatsPreview from "../../../../../interfaces/StatsPreview";
import { useCurrency } from "@appsinti/currency-provider";

const SalesGuestColumn: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  const { currency } = useCurrency();

  const sales = useMemo(() => {
    const { salesData } = preview;
    const {
      conversions,
      currency: salesCurrency,
      total,
      lunch,
      dinner,
    } = salesData;

    if (!currency) {
      return {
        salesPerGuest: total.salesPerGuest,
        lunchSalesPerGuest: lunch?.salesPerGuest ?? null,
        dinnerSalesPerGuest: dinner?.salesPerGuest ?? null,
      };
    }

    const conversion = conversions && conversions[currency];
    if (conversion) {
      return {
        salesPerGuest: conversion.total.salesPerGuest,
        lunchSalesPerGuest: conversion.lunch?.salesPerGuest ?? null,
        dinnerSalesPerGuest: conversion.dinner?.salesPerGuest ?? null,
      };
    }

    if (salesCurrency === currency) {
      return {
        salesPerGuest: total.salesPerGuest,
        lunchSalesPerGuest: lunch?.salesPerGuest ?? null,
        dinnerSalesPerGuest: dinner?.salesPerGuest ?? null,
      };
    }

    return {
      salesPerGuest: null,
      lunchSalesPerGuest: null,
      dinnerSalesPerGuest: null,
    };
  }, [preview.salesData, currency]);

  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Sales/Guest"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <MainColumn className="main smaller">
            <div className="column">
              <MetricData size={"small"} weight={"bold"} color={RIGHT_DARK}>
                ALL DAY
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {sales.salesPerGuest !== null
                  ? formatMoney(sales.salesPerGuest, { decimalCount: 0 })
                  : "-"}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"small"} weight={"bold"} color={RIGHT_DARK}>
                LUNCH
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {sales.lunchSalesPerGuest !== null
                  ? formatMoney(sales.lunchSalesPerGuest, { decimalCount: 0 })
                  : "-"}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"small"} weight={"bold"} color={RIGHT_DARK}>
                DINNER
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {sales.dinnerSalesPerGuest !== null
                  ? formatMoney(sales.dinnerSalesPerGuest, { decimalCount: 0 })
                  : "-"}
              </MetricData>
            </div>
          </MainColumn>
        </CustomRow>
      }
    />
  );
};

export default SalesGuestColumn;
