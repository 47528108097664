import React, { useMemo } from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import StatsPreview from "../../../../../interfaces/StatsPreview";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { formatMoneyMK } from "@appsinti/common-utils/textFormat";
import { CardTitleComponent, CustomRow, MainColumn } from "../../../styles";
import { useCurrency } from "@appsinti/currency-provider";

const SalesColumn: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  const { currency } = useCurrency();

  const sales = useMemo(() => {
    const { salesData } = preview;
    const {
      conversions,
      currency: salesCurrency,
      total,
      lunch,
      dinner,
    } = salesData;

    if (!currency) {
      return {
        totalSales: total.totalSales,
        lunchTotalSales: lunch?.totalSales ?? null,
        dinnerTotalSales: dinner?.totalSales ?? null,
      };
    }

    const conversion = conversions && conversions[currency];
    if (conversion) {
      return {
        totalSales: conversion.total.totalSales,
        lunchTotalSales: conversion.lunch?.totalSales ?? null,
        dinnerTotalSales: conversion.dinner?.totalSales ?? null,
      };
    }

    if (salesCurrency === currency) {
      return {
        totalSales: total.totalSales,
        lunchTotalSales: lunch?.totalSales ?? null,
        dinnerTotalSales: dinner?.totalSales ?? null,
      };
    }

    return {
      totalSales: null,
      lunchTotalSales: null,
      dinnerTotalSales: null,
    };
  }, [preview.salesData, currency]);

  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Sales"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <MainColumn className="main smaller">
            <div className="column">
              <MetricData size={"smaller"} weight={"bold"} color={RIGHT_DARK}>
                ALL DAY
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {sales.totalSales !== null
                  ? formatMoneyMK(sales.totalSales)
                  : "-"}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"smaller"} weight={"bold"} color={RIGHT_DARK}>
                LUNCH
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {sales.lunchTotalSales !== null
                  ? formatMoneyMK(sales.lunchTotalSales)
                  : "-"}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"smaller"} weight={"bold"} color={RIGHT_DARK}>
                DINNER
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {sales.dinnerTotalSales !== null
                  ? formatMoneyMK(sales.dinnerTotalSales)
                  : "-"}
              </MetricData>
            </div>
          </MainColumn>
        </CustomRow>
      }
    />
  );
};

export default SalesColumn;
