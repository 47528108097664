export const formatMoney = (
  amount: number = 0.0,
  options?: {
    decimalCount?: number;
    decimal?: string;
    thousands?: string;
    currencySymbol?: string;
  }
): string => {
  const {
    decimalCount = 2,
    decimal = ".",
    thousands = ",",
    currencySymbol = "$",
  } = options || {};

  try {
    const negativeSign = amount < 0 ? "-" : "";
    const absoluteValue = Math.abs(amount);

    const fixedPointValue = absoluteValue.toFixed(decimalCount);
    const integerPart = parseInt(fixedPointValue, 10);
    const integerPartString = integerPart.toString();
    const amountLength = integerPartString.length;

    const thousandPartsCount = Math.floor(amountLength / 3);
    const thousandPartModulus = amountLength % 3;

    const parts: string[] = [];

    for (let i = 1; i <= thousandPartsCount; i++) {
      parts.unshift(
        integerPartString.substring(
          amountLength - i * 3,
          amountLength - i * 3 + 3
        )
      );
    }
    if (thousandPartModulus !== 0) {
      parts.unshift(integerPartString.substring(0, thousandPartModulus));
    }
    const integerString =
      amountLength > 3 ? parts.join(thousands) : integerPartString;

    const fixedParts = fixedPointValue.split(".");
    const rawDecimalPart = fixedParts[1] || "";
    const zerosArray = Array(decimalCount).fill("");
    const decimalDigits = rawDecimalPart.split("");
    const decimalString = zerosArray
      .map((_, index) => (decimalDigits[index] ? decimalDigits[index] : "0"))
      .join("");

    return `${negativeSign}${currencySymbol}${integerString}${
      decimalString.length > 0 ? decimal : ""
    }${decimalString}`;
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const formatMoneyMK = (
  amount: number = 0.0,
  options?: {
    currencySymbol?: string;
  }
): string => {
  const { currencySymbol = "$" } = options || {};

  return `${
    amount > 999999
      ? formatMoney(amount / 1000, { decimalCount: 1, currencySymbol }) + "M"
      : amount > 999
      ? formatMoney(amount / 1000, { decimalCount: 1, currencySymbol }) + "K"
      : formatMoney(amount, { decimalCount: 0, currencySymbol })
  }`;
};

export const formatPercentage = (
  value: number,
  options?: {
    decimalCount?: number;
  }
) => {
  const { decimalCount = 2 } = options || {};

  const fixedPointValue = toFixed(value, decimalCount);

  return `${fixedPointValue}%`;
};

export const toFixed = (num: number, fixed: number) => {
  if(!num) return '0.0';
  let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  const match = num.toString().match(re);
  return match ? match[0] : '';
}