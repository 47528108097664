import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import { CustomRow, MainColumn } from "../../../styles";
import { formatPercentage } from "@appsinti/common-utils";
import { WRONG_DARK } from "@etiquette-ui/colors";
import StatsDay from "../../../../../interfaces/StatsDay";

const CancellationCourtesy: React.FC<{ day: StatsDay }> = ({ day }) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      body={
        <CustomRow>
          <MainColumn className="main">
            <div className="column">
              <MetricData size={"medium"} weight={"bold"}>
                CANCEL
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={WRONG_DARK}>
                {formatPercentage(day.salesData.total.cancellation, 1)}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"medium"} weight={"bold"}>
                COURTESY
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={WRONG_DARK}>
                {formatPercentage(day.salesData.total.courtesy, 1)}
              </MetricData>
            </div>
          </MainColumn>
        </CustomRow>
      }
    />
  );
};

export default CancellationCourtesy;
