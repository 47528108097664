import React, { useMemo } from "react";
import { useApps } from "pages/home/AppProvider";
import { useAccess } from "providers/access";
import { SummaryApp } from "@appsinti/common-utils/interfaces/IApp";
import IGroup from "@appsinti/common-utils/interfaces/IGroup";
import { BodyContainer, GroupContainer, GroupLink } from "./styles";
import ComponentPicker from "./ComponentPicker";

const getHeightPercentage = (groupsIds: any[]) => {
  if (groupsIds.length === 2) return 50;
  if (groupsIds.length === 3) return 33;
  if (groupsIds.length === 4) return 50;
  if (groupsIds.length === 5 || groupsIds.length === 6) return 33;
  return 32;
};

const Body: React.FC<{ bodyHeight: number }> = ({ bodyHeight }) => {
  const { summaryApps } = useApps();
  const { groups } = useAccess();
  const groupsIds = Object.keys(groups);

  const groupApps = useMemo(() => {
    const apps: { [key: number]: { group: IGroup; app?: SummaryApp } } = {};
    for (const groupId in groups) {
      apps[+groupId] = { group: groups[+groupId] };
      const currentGroupApps = summaryApps?.filter((app) => app.groupId === +groupId) ?? []
      if(currentGroupApps?.length > 0) {
        apps[+groupId].app = currentGroupApps[0];
      }
    }
    return apps;
  }, [groups, summaryApps]);

  return (
    <BodyContainer
      bodyHeight={bodyHeight}
      className={groupsIds.length > 3 ? "row" : ""}
    >
      {Object.values(groupApps).map(({ group, app }) => {
        return (
          <GroupContainer
            key={group.id}
            background={"white"}
            noPadding={true}
            className={groupsIds.length > 3 ? "half-width" : "full-width"}
            heightPercentage={getHeightPercentage(groupsIds)}
            body={
              <GroupLink to={`/${group.id}`}>
                <ComponentPicker app={app} groupId={group.id} />
              </GroupLink>
            }
          />
        );
      })}
    </BodyContainer>
  );
};

export default Body;
