import React from "react";
import CurrentTemperature from "@appsinti/common-utils/interfaces/weather/CurrentTemperature";
import { WeatherContainer, WeatherIconContainer } from "./cardStyles";
import WeatherIcon from "@appsinti/ui-core/weather/Icon";
import { useTemperature } from "@appsinti/temperature-provider";
import useI18n from "@appsinti/i18n/useI18n";
import { HeightRestriction, WidthRestriction } from "../../interfaces/Restrictions";

const WeatherInfo: React.FC<{
  widthRestriction: WidthRestriction;
  heightRestriction: HeightRestriction;
  weatherData: CurrentTemperature;
}> = ({ widthRestriction, heightRestriction, weatherData }) => {
  const { temperatureObject } = useTemperature();
  const { translateObject } = useI18n();

  return (
    <WeatherContainer className={`${widthRestriction} ${heightRestriction}`}>
      <WeatherIconContainer className={`${widthRestriction} ${heightRestriction}`}>
        <WeatherIcon name={weatherData.icon} />
      </WeatherIconContainer>
      <p>{temperatureObject(weatherData.temperature)}</p>
      {widthRestriction === "full-width" && (
        <div>
          <p>{translateObject(weatherData.summary)}</p>
          <p>{`${temperatureObject(
            weatherData.minTemperature
          )} / ${temperatureObject(weatherData.maxTemperature)}`}</p>
        </div>
      )}
    </WeatherContainer>
  );
};

export default WeatherInfo;
