import React, { useState } from "react";
import MenuIcon from "resources/img/menu-icon.svg";
import styled from "styled-components";
import LateralMenu from "./LateralMenu";
import { PRIMARY } from "@etiquette-ui/colors";
import { useCurrencySelector } from "@appsinti/currency-provider";

const Container = styled.div`
  width: 23%;
  max-width: 23%;
  justify-content: flex-end;
  display: flex;
  order: 4;
  gap: 7px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Menu = styled.img`
  width: 25px;
  height: 25px;
`;

const CurrencySelect = styled.button`
  flex: 0 0 auto;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  border: none;
  background: none;
  cursor: pointer;
  color: ${PRIMARY};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CurrencyOptionsContainer = styled.div<{ headerHeight?: number }>`
  position: absolute;
  top: 25px;
  display: flex;
  justify-content: center;
  z-index: 5;
  width: max-content;
`;

const CurrencyOptionsBackground = styled.div`
  background: white;
  padding: 4px;
  border-radius: 5px;
  box-shadow: 0px 8px 24px #0332693d;
  display: flex;
  flex-direction: column;
  gap: 3px;

  span {
    padding: 2px 5px;
    border-radius: 5px;
    &.selected {
      background: ${PRIMARY};
      color: white;
    }
  }
`;

export const ShowMenu: React.FC<{ headerHeight: number | undefined }> = ({
  headerHeight,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currencyOpen, setCurrencyOpen] = useState<boolean>(false);
  const {
    currency,
    setCurrency,
    supportedCurrencies,
    isVisible,
    currencyEnabled,
  } = useCurrencySelector();

  const toggle = () => setIsOpen(!isOpen);
  const toggleCurrency = () => {
    setCurrencyOpen(!currencyOpen);
  };

  const onSelectCurrency = (c: string | null) => {
    setCurrency(c);
    setCurrencyOpen(false);
  };

  return (
    <>
      <Container>
        {isVisible && currencyEnabled && (
          <CurrencySelect onClick={toggleCurrency}>
            {currency === null ? "NO FX" : currency}
            {currencyOpen && (
              <CurrencyOptionsContainer headerHeight={headerHeight}>
                <CurrencyOptionsBackground>
                  <span
                        className={null === currency ? "selected" : ""}
                        onClick={() => onSelectCurrency(null)}
                      >
                        NO FX
                  </span>
                  {supportedCurrencies.map((c, index) => (
                    <React.Fragment key={c}>
                      <span
                        className={c === currency ? "selected" : ""}
                        onClick={() => onSelectCurrency(c)}
                      >
                        {c}
                      </span>
                    </React.Fragment>
                  ))}
                </CurrencyOptionsBackground>
              </CurrencyOptionsContainer>
            )}
          </CurrencySelect>
        )}
        <div onClick={toggle}>
          <Menu src={MenuIcon} alt={"isOpen-menu"} />
        </div>
      </Container>
      {isOpen && <LateralMenu toggle={toggle} isOpen={isOpen} />}
    </>
  );
};
